import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  container,
  column,
  expertises,
  title,
  link as linkClass,
  chevron,
} from "./expertise-listing.module.scss";

import Chevron from "../assets/images/svg/chevron.svg";

import MainLayout from "../layouts/main-layout";
import Title from "../components/atoms/title";
import { getNodes } from "../utils/get-nodes";
import { Link } from "gatsby-plugin-react-i18next";
import ExpertiseModalNav from "../components/molecules/expertise-modal-nav";

interface IExpertiseProps {
  className: string;
}

const ExpertiseListingPage: React.FC<IExpertiseProps> = ({ data }) => {
  const { t } = useTranslation();

  const { allDispute, allProtect, allRegulatory } = data;

  const dispute = getNodes(allDispute);
  const protect = getNodes(allProtect);
  const regulatory = getNodes(allRegulatory);

  return (
    <MainLayout
      titleSeo={t("expertise.title")}
      descriptionSeo={t("expertise.title")}
    >
      <Title Tag="h1" size="large" align="center">
        {t("expertise.title")}
      </Title>
      <div className={container}>
        <div className={column}>
          <h2 className={title}>{t("category.dispute")}</h2>
          <div className={expertises}>
            {dispute.map((link, index) => {
              return (
                <Link to={`/${link.slug}`} className={linkClass} key={index}>
                  <span> {link.title}</span> <Chevron className={chevron} />
                </Link>
              );
            })}
          </div>
        </div>
        <div className={column}>
          <h2 className={title}>{t("category.protect")}</h2>
          <div className={expertises}>
            {protect.map((link, index) => {
              return (
                <Link to={`/${link.slug}`} className={linkClass} key={index}>
                  <span> {link.title}</span>
                  <Chevron className={chevron} />
                </Link>
              );
            })}
          </div>
        </div>
        <div className={column}>
          <h2 className={title}>{t("category.regulatory")}</h2>
          <div className={expertises}>
            {regulatory.map((link, index) => {
              return (
                <Link to={`/${link.slug}`} className={linkClass} key={index}>
                  <span> {link.title}</span> <Chevron className={chevron} />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allDispute: allStrapiExpertise(
      filter: {
        category: { category_name: { eq: "dispute" } }
        locale: { eq: $language }
      }
    ) {
      edges {
        node {
          slug
          title
        }
      }
    }
    allProtect: allStrapiExpertise(
      filter: {
        category: { category_name: { eq: "protect" } }
        locale: { eq: $language }
      }
    ) {
      edges {
        node {
          slug
          title
        }
      }
    }
    allRegulatory: allStrapiExpertise(
      filter: {
        category: { category_name: { eq: "regulatory" } }
        locale: { eq: $language }
      }
    ) {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;

export default ExpertiseListingPage;
